.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
     -moz-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.66667%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
     -moz-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 8.33333%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.66667%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 41.66667%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 58.33333%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 66.66667%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 75%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 83.33333%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 91.66667%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
     -moz-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
  -webkit-order: 13;
     -moz-box-ordinal-group: 14;
      -ms-flex-order: 13;
          order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
     -moz-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
     -moz-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
     -moz-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
     -moz-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
  -webkit-order: 4;
     -moz-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
  -webkit-order: 5;
     -moz-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
  -webkit-order: 6;
     -moz-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
  -webkit-order: 7;
     -moz-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
  -webkit-order: 8;
     -moz-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
  -webkit-order: 9;
     -moz-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
  -webkit-order: 10;
     -moz-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
  -webkit-order: 11;
     -moz-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
  -webkit-order: 12;
     -moz-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box; }

html {
  overflow-x: hidden; }

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #000;
  position: relative;
  background-color: #111; }

input, textarea, button, select {
  font-family: "Roboto", sans-serif; }

a {
  text-decoration: none; }

section {
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.btn {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 200px;
  height: 55px;
  margin: 0;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer; }
  .btn span {
    font-size: 1.125rem;
    letter-spacing: 1px;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-transform: translateZ(-25px);
       -moz-transform: translateZ(-25px);
            transform: translateZ(-25px);
    -webkit-transition: -webkit-transform .25s;
    transition: -webkit-transform .25s;
    transition: transform .25s;
    transition: transform .25s, -webkit-transform .25s, -moz-transform .25s;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%; }
    .btn span:before, .btn span:after {
      position: absolute;
      content: attr(data-text);
      text-transform: uppercase;
      height: 55px;
      width: 200px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
         -moz-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      border: 5px solid #ff5722;
      -webkit-box-sizing: border-box;
         -moz-box-sizing: border-box;
              box-sizing: border-box;
      -webkit-border-radius: 5px;
         -moz-border-radius: 5px;
              border-radius: 5px; }
    .btn span:before {
      color: #fff;
      background: #ff5722;
      -webkit-transform: rotateY(0deg) translateZ(25px);
         -moz-transform: rotateY(0deg) translateZ(25px);
              transform: rotateY(0deg) translateZ(25px); }
    .btn span:after {
      color: #ff5722;
      -webkit-transform: rotateX(90deg) translateZ(25px);
         -moz-transform: rotateX(90deg) translateZ(25px);
              transform: rotateX(90deg) translateZ(25px); }
    .btn span:hover {
      -webkit-transform: translateZ(-25px) rotateX(-90deg);
         -moz-transform: translateZ(-25px) rotateX(-90deg);
              transform: translateZ(-25px) rotateX(-90deg); }

.header {
  background: white;
  height: 80px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0px 5px 0px 0px #ff5722;
     -moz-box-shadow: 0px 5px 0px 0px #ff5722;
          box-shadow: 0px 5px 0px 0px #ff5722;
  position: relative;
  z-index: 1000; }
  .header .container {
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .header .container > .row {
      width: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .header .container > .row .col-lg-3:last-child {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
           -moz-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end; }
  .header .logo {
    font-family: "Righteous", cursive;
    text-transform: uppercase;
    color: #111;
    font-size: 1.75rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 48px; }
    .header .logo img {
      height: 38px;
      margin: 0 6px 0 0; }
    .header .logo > span {
      position: relative;
      display: inline-block;
      height: 100%; }
      .header .logo > span > span {
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        text-transform: initial;
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        text-align: left; }
  .header .socials {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .header .socials__phone {
      font-size: 1.125rem;
      color: black;
      font-weight: bold;
      display: inline-block;
      margin: 0 1rem 0 0;
      -webkit-transition: ease-in .3s;
      transition: ease-in .3s; }
      .header .socials__phone:hover {
        color: #ff5722; }
    .header .socials__item {
      width: 34px;
      height: 34px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
         -moz-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin: 0 0 0 1rem;
      padding: 9px;
      -webkit-border-radius: 100%;
         -moz-border-radius: 100%;
              border-radius: 100%;
      background: #ff5722;
      -webkit-transition: ease-in .3s;
      transition: ease-in .3s; }
      .header .socials__item svg {
        width: 100%;
        height: auto; }
        .header .socials__item svg .i_color {
          -webkit-transition: ease-in .3s;
          transition: ease-in .3s;
          fill: white; }
          .header .socials__item svg .i_color.wa-2 {
            fill: #ff5722; }
      .header .socials__item:hover {
        background-color: white;
        padding: 6px; }
        .header .socials__item:hover svg .i_color.tg {
          fill: #039be5; }
        .header .socials__item:hover svg .i_color.wa {
          fill: #4CAF50; }
          .header .socials__item:hover svg .i_color.wa-2 {
            fill: white; }
        .header .socials__item:hover svg .i_color.p {
          fill: #ff5722; }
  .header .darkmode {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 1.5rem; }
    .header .darkmode .switch {
      margin: 0 0 0 .5rem; }
  .header__menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0; }
    .header__menu li {
      margin-right: 20px; }
      .header__menu li:last-child {
        margin-right: 0; }
      .header__menu li a {
        font-size: 1.125rem;
        color: black;
        font-weight: normal;
        -webkit-transition: ease-in .3s;
        transition: ease-in .3s; }
        .header__menu li a:hover {
          color: #ff5722; }

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px; }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0; }
    .switch input:checked + .slider {
      background-color: #ff5722; }
    .switch input:checked + .slider:before {
      -webkit-transform: translateX(26px);
         -moz-transform: translateX(26px);
          -ms-transform: translateX(26px);
              transform: translateX(26px); }
  .switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s; }
    .switch .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s; }
    .switch .slider.round {
      -webkit-border-radius: 34px;
         -moz-border-radius: 34px;
              border-radius: 34px; }
      .switch .slider.round:before {
        -webkit-border-radius: 50%;
           -moz-border-radius: 50%;
                border-radius: 50%; }

.title {
  font-size: 4rem;
  color: white;
  font-weight: bold;
  margin: 0; }

.subtitle {
  margin: 0;
  color: white;
  font-weight: 300;
  font-size: 1.3125rem; }

.input {
  position: relative;
  display: block;
  width: 300px;
  margin: 2rem auto 0 auto; }
  .input__label {
    position: absolute;
    left: 0;
    top: 0;
    padding: 6px;
    margin: 6px;
    white-space: nowrap;
    -webkit-transform: translate(0, 0);
       -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
            transform: translate(0, 0);
    -webkit-transform-origin: 0 0;
       -moz-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transition: -webkit-transform 120ms ease-in;
    transition: -webkit-transform 120ms ease-in;
    transition: transform 120ms ease-in;
    transition: transform 120ms ease-in, -webkit-transform 120ms ease-in, -moz-transform 120ms ease-in;
    line-height: 1.2;
    color: #7a7a7a; }
  .input__field {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    display: block;
    width: 100%;
    border: none;
    padding: -webkit-calc(.5rem * 1.5) 1rem;
    padding: calc(.5rem * 1.5) 1rem;
    color: white;
    background: #2e2e2e;
    -webkit-border-radius: 4px;
       -moz-border-radius: 4px;
            border-radius: 4px;
    outline: none;
    -webkit-transition: ease-in .3s;
    transition: ease-in .3s; }
    .input__field:hover, .input__field:focus {
      background-color: #3a3a3a; }
    .input__field:not(:-moz-placeholder-shown) + .input__label {
      -moz-transform: translate(0.25rem, -65%) scale(0.8);
           transform: translate(0.25rem, -65%) scale(0.8);
      color: #ff5722; }
    .input__field:not(:-ms-input-placeholder) + .input__label {
      -ms-transform: translate(0.25rem, -65%) scale(0.8);
          transform: translate(0.25rem, -65%) scale(0.8);
      color: #ff5722; }
    .input__field:focus + .input__label, .input__field:not(:placeholder-shown) + .input__label {
      -webkit-transform: translate(0.25rem, -65%) scale(0.8);
         -moz-transform: translate(0.25rem, -65%) scale(0.8);
          -ms-transform: translate(0.25rem, -65%) scale(0.8);
              transform: translate(0.25rem, -65%) scale(0.8);
      color: #ff5722; }

.start {
  height: -webkit-calc(100vh - 80px);
  height: calc(100vh - 80px);
  overflow: hidden;
  position: relative; }
  .start .fp-tableCell {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .start .animation-wrapper {
    width: 40%;
    height: 550px;
    position: absolute;
    left: 0;
    top: 0; }
    .start .animation-wrapper .stagger-visualizer {
      position: absolute;
      width: 550px;
      height: 550px;
      -webkit-transform-origin: left top;
         -moz-transform-origin: left top;
          -ms-transform-origin: left top;
              transform-origin: left top; }
      .start .animation-wrapper .stagger-visualizer .dots-wrapper {
        -webkit-transform: translateZ(0);
           -moz-transform: translateZ(0);
                transform: translateZ(0);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        .start .animation-wrapper .stagger-visualizer .dots-wrapper .dot {
          position: relative;
          z-index: 1;
          width: 23px;
          height: 23px;
          margin: 16px;
          -webkit-border-radius: 50%;
             -moz-border-radius: 50%;
                  border-radius: 50%;
          background-color: transparent;
          background-image: -webkit-gradient(linear, left top, left bottom, color-stop(8%, #cacaca), to(#484745));
          background-image: -webkit-linear-gradient(top, #cacaca 8%, #484745 100%);
          background-image: -moz-linear-gradient(top, #cacaca 8%, #484745 100%);
          background-image: linear-gradient(180deg, #cacaca 8%, #484745 100%); }
      .start .animation-wrapper .stagger-visualizer .cursor {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 37px;
        height: 37px;
        margin: 9px;
        background-color: currentColor;
        -webkit-border-radius: 50%;
           -moz-border-radius: 50%;
                border-radius: 50%;
        color: #ff5722; }
  .start__title {
    font-size: 3.75rem;
    color: white;
    font-weight: bold;
    margin: 0; }
    .start__title span {
      color: #ff5722; }
    .start__title .a-emoji {
      width: 66px;
      position: relative;
      top: 6px; }
  .start__description {
    font-size: 1.125rem;
    color: rgba(255, 255, 255, 0.9);
    margin: 2rem 0 0 0;
    line-height: 1.5;
    font-weight: 300;
    max-width: 80%; }
  .start .btn {
    margin: 3.5rem 0 0 0; }

.works {
  background-color: white; }
  .works .fp-tableCell {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .works .title {
    text-align: center;
    color: black; }
    .works .title .a-emoji {
      width: 71px;
      position: relative;
      top: 8px;
      left: -6px; }
  .works .project__image {
    -webkit-box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.4);
       -moz-box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.4);
            box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.4);
    -webkit-border-radius: 8px;
       -moz-border-radius: 8px;
            border-radius: 8px;
    height: 500px;
    overflow: auto; }
    .works .project__image .simplebar-content-wrapper {
      -webkit-border-radius: 8px;
         -moz-border-radius: 8px;
              border-radius: 8px; }
    .works .project__image img {
      width: 100%; }
  .works .project__title {
    display: block;
    text-align: center;
    font-size: 1.3125rem;
    font-weight: 300;
    margin: 21px 0 0 0;
    color: rgba(0, 0, 0, 0.9); }
  .works .swiper-container {
    width: 100%;
    height: 600px;
    margin: 60px 0;
    padding: 10px; }
  .works .swiper-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }

.services .fp-tableCell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.services .title {
  text-align: center; }
  .services .title .a-emoji {
    width: 71px;
    position: relative;
    top: 15px;
    left: -6px; }

.services .subtitle {
  text-align: center;
  margin: 21px 0 0 0;
  color: rgba(255, 255, 255, 0.9); }

.services .item {
  height: 300px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid rgba(255, 255, 255, 0.5);
  padding: 30px 15px 15px 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin: 80px 0 0 0;
  -webkit-border-radius: 8px;
     -moz-border-radius: 8px;
          border-radius: 8px;
  -webkit-transition: ease-in .3s;
  transition: ease-in .3s; }
  .services .item__image {
    display: block;
    width: 70px;
    height: 70px;
    margin-bottom: 1rem;
    -moz-background-size: cover;
         background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  .services .item__label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75rem; }
    .services .item__label_dev {
      color: #ff5722; }
    .services .item__label_integrate {
      color: #ee3767; }
    .services .item__label_support {
      color: #765397; }
  .services .item__name {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold; }
  .services .item .tags {
    margin: 15px 0 0 0;
    height: 0;
    overflow: hidden;
    display: block;
    -webkit-transition: ease-in .3s;
    transition: ease-in .3s; }
    .services .item .tags__item {
      font-size: 0.75rem;
      padding: 5px 7px;
      background-color: white;
      margin: 0 7px 7px 0;
      -webkit-border-radius: 3px;
         -moz-border-radius: 3px;
              border-radius: 3px;
      height: 24px;
      display: inline-block; }
      .services .item .tags__item_orange {
        background-color: #ff5722;
        color: white; }
      .services .item .tags__item_purple {
        background-color: #765397;
        color: white; }
  .services .item__desc {
    display: block;
    margin: 15px 0 0 0;
    color: rgba(0, 0, 0, 0.75);
    height: 0;
    overflow: hidden;
    -webkit-transition: ease-in .3s;
    transition: ease-in .3s; }
  .services .item:hover {
    background-color: white;
    border: 2px solid white; }
    .services .item:hover .item__desc {
      height: 65px; }
    .services .item:hover .tags {
      height: 93px; }

.callback {
  position: relative; }
  .callback .fp-tableCell {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative; }
  .callback .title {
    text-align: center;
    margin: 3rem 0 0 0; }
  .callback .noUi-pips {
    color: white; }
  .callback .noUi-value-sub {
    color: white;
    font-size: 0.75rem; }
  .callback .noUi-value {
    margin: 10px 0 0 0; }
  .callback .noUi-connect {
    background: #ff5722; }
  .callback .noUi-target {
    border: none;
    background: white;
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none; }
  .callback .noUi-handle {
    border: none;
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;
    width: 28px;
    -webkit-border-radius: 100%;
       -moz-border-radius: 100%;
            border-radius: 100%;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
       -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
            box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
    cursor: pointer; }
    .callback .noUi-handle:before, .callback .noUi-handle:after {
      background: #ff5722; }
    .callback .noUi-handle:before {
      left: -webkit-calc(50% - 2px);
      left: calc(50% - 2px); }
    .callback .noUi-handle:after {
      left: -webkit-calc(50% + 1px);
      left: calc(50% + 1px); }
  .callback .steps__progress {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    max-width: 1140px;
    width: 100%; }
    .callback .steps__progress_wrap {
      position: absolute;
      width: 100%;
      left: 0;
      top: 120px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
         -moz-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .callback .steps__progress:before {
      content: '';
      width: 100%;
      height: 2px;
      background-color: #ff5722;
      position: absolute;
      left: 0;
      top: -webkit-calc(50% - 1px);
      top: calc(50% - 1px);
      z-index: 0; }
    .callback .steps__progress span {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
         -moz-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: 3rem;
      height: 3rem;
      -webkit-border-radius: 100%;
         -moz-border-radius: 100%;
              border-radius: 100%;
      border: 2px solid #ff5722;
      color: white;
      position: relative;
      z-index: 1;
      background-color: #111;
      -webkit-transition: ease-in .3s;
      transition: ease-in .3s; }
      .callback .steps__progress span.active {
        background-color: #ff5722; }
  .callback .steps .step {
    text-align: center; }
    .callback .steps .step .btn {
      margin: 1.5rem 0 0 0; }
    .callback .steps .step__1 #pips-steps {
      margin: 4rem; }
    .callback .steps .step__1 .summ {
      margin: 6rem 0 0 0;
      font-size: 1.5rem;
      color: white; }
      .callback .steps .step__1 .summ span {
        font-weight: bold;
        color: #ff5722; }
    .callback .steps .step__2 {
      display: none; }
      .callback .steps .step__2 .type {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
           -moz-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        margin: 6rem 0 3rem 0; }
        .callback .steps .step__2 .type > span {
          font-size: 1.125rem;
          color: white;
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -webkit-align-items: center;
             -moz-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
          .callback .steps .step__2 .type > span > i {
            font-style: normal;
            font-size: 2rem;
            display: inline-block;
            margin: -8px 8px 0 0; }
        .callback .steps .step__2 .type .switch {
          margin: 1rem 0 0 0; }
    .callback .steps .step__3 {
      display: none; }
      .callback .steps .step__3 div {
        margin: 80px 0 0 0; }
  .callback .steps .success {
    display: none; }
    .callback .steps .success img {
      width: 200px;
      display: block;
      margin: 0 auto; }
    .callback .steps .success__title {
      font-size: 3rem;
      font-weight: bold;
      color: white;
      text-align: center;
      margin: 2rem 0 0 0; }
    .callback .steps .success__text {
      text-align: center;
      font-size: 2rem;
      color: white;
      margin: 1rem 0 0 0; }
  .callback__politic {
    display: block;
    max-width: 300px;
    margin: 1rem auto 0 auto;
    text-align: left;
    color: #ffffff36;
    font-size: 12px;
    font-weight: 300; }
    .callback__politic a {
      color: #ff5722; }

@media (max-width: 991px) {
  section {
    height: auto;
    display: block; }
  .title {
    font-size: 1.7rem;
    position: relative;
    padding: 0 0 0 64px;
    text-align: left;
    font-weight: 900; }
  .header {
    height: 60px; }
    .header .container {
      width: 100%; }
      .header .container > .row {
        width: 100%; }
    .header .col-lg-6 {
      display: none; }
    .header .col-6 {
      padding: 0 !important; }
    .header .logo {
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .header .logo img {
        height: 30px; }
      .header .logo span {
        font-size: 18px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        .header .logo span span, .header .logo span br {
          display: none; }
  .start {
    height: -webkit-calc(100vh - 60px);
    height: calc(100vh - 60px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .start .animation-wrapper {
      width: 100%; }
    .start__title {
      font-size: 3rem; }
      .start__title > span {
        display: inline-block;
        width: 100%; }
      .start__title .a-emoji {
        width: 52px; }
    .start .container::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      position: absolute;
      left: 0;
      top: 0; }
  .works {
    padding: 60px 0; }
    .works .title {
      text-align: left; }
      .works .title .a-emoji {
        position: absolute;
        left: 0;
        top: 0;
        width: 62px; }
    .works .device {
      -webkit-transform: scale(0.5);
         -moz-transform: scale(0.5);
          -ms-transform: scale(0.5);
              transform: scale(0.5);
      left: -46%;
      margin: -60px 0 0 0; }
    .works .swiper-container {
      margin: 60px 0 0 0; }
  .services {
    padding: 60px 0; }
    .services .title {
      text-align: left; }
      .services .title .a-emoji {
        position: absolute;
        left: 0;
        top: 0;
        width: 62px; }
    .services .subtitle {
      font-size: 0.875rem;
      padding: 0 0 0 58px;
      margin: 10px 0 0 0; }
    .services .item {
      height: auto;
      background-color: white;
      border: 2px solid white;
      margin: 30px 0 0 0; }
      .services .item .item__desc, .services .item .tags {
        height: auto; }
      .services .item:hover {
        background-color: white;
        border: 2px solid white; }
        .services .item:hover .item__desc, .services .item:hover .tags {
          height: auto; }
  .callback {
    padding: 120px 0 60px 0; }
    .callback .title {
      padding: 0;
      margin: 1rem 0 0 0; }
    .callback .steps .step__1 #pips-steps {
      margin: 4rem 0; }
      .callback .steps .step__1 #pips-steps .noUi-value {
        display: none; }
    .callback .steps .step__1 .summ {
      margin: 3rem 0 0 0; }
    .callback .steps .step__2 .type {
      margin: 1.5rem 0 0 0;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
         -moz-box-orient: horizontal;
         -moz-box-direction: reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
         -moz-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .callback .steps .step__2 .type > span {
        font-size: 0.875rem;
        text-align: left; }
        .callback .steps .step__2 .type > span > i {
          font-size: 1.7rem; }
      .callback .steps .step__2 .type .switch {
        margin: 0 10px 0 0; }
    .callback .steps .step .btn {
      margin: 3rem 0 0 0; }
    .callback .steps .success {
      margin: 0 0 60px 0; }
      .callback .steps .success img {
        width: 100px; }
      .callback .steps .success__title {
        font-size: 1.7rem; }
      .callback .steps .success__text {
        font-size: 1rem;
        margin: .5rem 0 0 0;
        font-weight: 300; }
    .callback .steps__progress_wrap {
      top: 60px;
      padding: 0 15px; } }

@media (max-width: 375px) {
  .works .device {
    left: -57%; } }
